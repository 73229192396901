// USA
export const locale = {
  TRANSLATOR: {
    SELECT: "Select your language"
  },
  ERROR_500: "Something went wrong",
  NO_DATA_FOUND: "No data found",
  MENU: {
    NEW: "New",
    ACTIONS: "Actions",
    CREATE_POST: "Create New Post",
    PAGES: "Pages",
    FEATURES: "Features",
    APPS: "Apps",
    DASHBOARD: "Dashboard",
    PODCASTS: "Podcasts",
    STATS: "Statistics",
    AUDIENCE: "Audience",
    PROFILE: "Manage account",
    SPONSORING: "Sponsoring",
    FOLLOWERS: "Followers",
    MANAGECAMPAIGNS: "Manage Campaigns",
    DELIVERY: "Delivery",
    BROADCAST: "Broadcast",
    MANAGEREVENUS: "Native revenues",
    ADSTUDIO: "AdStudio",
    DISCOVERY: {
      SECTION: "Discovery",
      SIMULATOR: "Simulator",
      MONITORING: {
        PAGES: "Pages Monitoring"
      }
    },
    ANALYTICS: "Analytics",
    NEWCAMPAIGN: "New campaign",
    REVENUS: "Revenues",
    ACCOUNT: "Account",
    INVOICES: "Invoices",
    ADVANCED_SETTINGS: {
      HOSTING: "Hosting",
      MY_COMPANY: "My Company",
      DOMAINS: "Domains",
      PLAYLIST: "Playlists"
    },

    HELP: {
      SECTION: "Help",
      FAQ: "FAQ"
    },
    CONFIGURATION: {
      MAIN: "Configuration",
      USERS: "Users",
      COMPANIES: "Companies",
      PODCASTS: "Podcasts",
      WEBSITES: "Websites",
      PARAMETERS: "Parameters",
      CATEGORIES: "Categories",
      GENERATOR: {
        EDITOR: "Title generator"
      }
    },
    TRENDS: "Trends"
  },
  TYPES: {
    company: {
      LABEL: "Company | Companies"
    }
  },
  INFORMATIONS: {
    EXPIRED_PASSWORD_DAYS:
      "Please note that your password is about to expire in {days} day(s). Remember to renew your password before the {date}",
    REVENUE_DATA_START:
      "Earnings unavailable for selected dates: please select dates starting from August 2022"
  },
  AUTH: {
    GENERAL: {
      OR: "Or",
      SUBMIT_BUTTON: "Submit",
      NO_ACCOUNT: "Don't have an account?",
      SIGNUP_BUTTON: "Sign Up",
      FORGOT_BUTTON: "Forgot Password",
      BACK_BUTTON: "Back",
      PRIVACY: "Privacy",
      LEGAL: "Legal",
      CONTACT: "Contact"
    },
    LOGIN: {
      TITLE: "Login Account",
      BUTTON: "Sign In",
      FORGOT: "Forgot Password?",
      ERRORS: {
        INVALID_CREDENTIALS: "Login or password invalid !",
        LOGIN_EXPIRED_PASSWORD:
          "Your password has just expired. Please click here to renew it !"
      }
    },
    FORGOT: {
      TITLE: "Reset Your Voxeus Password",
      DESC: "Enter your email to reset your password",
      SUCCESS: "Your account has been successfully reset.",
      BUTTON: "Reset Password"
    },
    RESET: {
      TITLE: "Reset Your Voxeus Password",
      MESSAGE: "Please enter your new password to log in to the platform.",
      BUTTON: "Save Password"
    },
    WELCOME: {
      TITLE: "Welcome",
      MESSAGE:
        "Welcome to the Voxeus platform, please enter a password to log in.",
      BUTTON: "Register"
    },
    REGISTER: {
      TITLE: "Sign Up",
      DESC: "Enter your details to create your account",
      SUCCESS: "Your account has been successfuly registered."
    },
    INPUT: {
      EMAIL: "Email",
      FULLNAME: "Fullname",
      PASSWORD: "Password",
      CONFIRM_PASSWORD: "Confirm Password",
      USERNAME: "Username"
    },
    VALIDATION: {
      INVALID: "{{name}} is not valid",
      REQUIRED: "{{name}} is required",
      MIN_LENGTH: "{{name}} minimum length is {{min}}",
      AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
      NOT_FOUND: "The requested {{name}} is not found",
      INVALID_LOGIN: "The login detail is incorrect",
      REQUIRED_FIELD: "Required field",
      MIN_LENGTH_FIELD: "Minimum field length:",
      MAX_LENGTH_FIELD: "Maximum field length:",
      INVALID_FIELD: "Field is not valid",
      INVALID_TOKEN:
        "This reset password link has expired. Please contact us: ",
      SOMETHING_WENT_WRONG: "Something went wrong"
    }
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: "Selected records count: ",
      ALL: "All",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      FILTER: "Filter",
      BY_STATUS: "by Status",
      BY_TYPE: "by Type",
      BUSINESS: "Business",
      INDIVIDUAL: "Individual",
      SEARCH: "Search",
      IN_ALL_FIELDS: "in all fields"
    },
    ECOMMERCE: "eCommerce",
    CUSTOMERS: {
      CUSTOMERS: "Customers",
      CUSTOMERS_LIST: "Customers list",
      NEW_CUSTOMER: "New Customer",
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: "Customer Delete",
        DESCRIPTION: "Are you sure to permanently delete this customer?",
        WAIT_DESCRIPTION: "Customer is deleting...",
        MESSAGE: "Customer has been deleted"
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: "Customers Delete",
        DESCRIPTION: "Are you sure to permanently delete selected customers?",
        WAIT_DESCRIPTION: "Customers are deleting...",
        MESSAGE: "Selected customers have been deleted"
      },
      UPDATE_STATUS: {
        TITLE: "Status has been updated for selected customers",
        MESSAGE: "Selected customers status have successfully been updated"
      },
      EDIT: {
        UPDATE_MESSAGE: "Customer has been updated",
        ADD_MESSAGE: "Customer has been created"
      }
    }
  },
  DATATABLE: {
    NO_DATA: "No data to display",
    PROGRAMS: "programs",
    LOADING: "Loading",
    LOADING_DATA: "Loading data"
  },
  TRENDS: {
    TAB_AUDIENCE: {
      TITLE_TAB: "Audiences",
      FIRST_TABLE: {
        TITLE: "Top plays per domain",
        FIRST_COLUMN: "Domains",
        SECOND_COLUMN: "Plays",
        THIRD_COLUMN: "% plays",
        FOURTH_COLUMN: "Pages",
        FITH_COLUMN: "% plays per pages"
      },
      SECOND_TABLE: {
        TITLE: "Top plays per program",
        FIRST_COLUMN: "Program",
        SECOND_COLUMN: "Plays",
        THIRD_COLUMN: "Pages"
      }
    },
    TAB_REFERERS: {
      TITLE_TAB: "Sources",
      FIRST_TABLE: {
        TITLE: "Top sources per domain",
        FIRST_COLUMN: "Sources",
        SECOND_COLUMN: "Sessions",
        THIRD_COLUMN: "Pages",
        FOURTH_COLUMN: "Plays"
      }
    },
    TAB_REVENUES: {
      TITLE_TAB: "Revenues",
      FIRST_TABLE: {
        TITLE: "Top revenues per domain",
        FIRST_COLUMN: "Domains",
        SECOND_COLUMN: "Count"
      },
      SECOND_TABLE: {
        TITLE: "Top revenues per program",
        FIRST_COLUMN: "Program",
        SECOND_COLUMN: "Revenues"
      }
    },
    TAB_INVENTORY: {
      TITLE_TAB: "Inventories",
      FIRST_TABLE: {
        TITLE: "Top inventories per Domain",
        FIRST_COLUMN: "Domains",
        SECOND_COLUMN: "Inventories",
        THIRD_COLUMN: "% inventories"
      },
      SECOND_TABLE: {
        TITLE: "Top inventories per program",
        FIRST_COLUMN: "Program",
        SECOND_COLUMN: "Inventory"
      }
    }
  },
  BROADCAST: {
    NAME: "Name",
    STATUS: "Status",
    START_DATE: "Date start",
    END_DATE: "Date end",
    DIFF_DAY: "Day diff",
    BUDGET: "Budget",
    COST_PER_LISTEN: "Cpl (Cost per listens)",
    DAILY_RATIO: "Ratio daily",
    RATIO: "Ratio",
    SPENT: "Spent",
    PACING: "Pacing",
    TOTAL_LISTENS_DELIVER: "Total Listens To Deliver",
    DELIVERED_LISTENS: "Delivered Listens",
    REMAINING_LISTENS: "Remaining Listens",
    LISTENS_DELIVER: "Listens to deliver per day",
    TOTAL_RATIO: "Ratio total",
    DAY_LEFT: "Days left",
    BUDGET_LEFT: "Budget left"
  },
  DELIVERY: {
    PROGRAM: "Program",
    DOMAIN: "Domain",
    SPONSORED: "Sponsored Play",
    INVENTORY: "Inventory",
    FILL_RATING: "Fill rating"
  },
  SPONSORING_EDIT: {
    HELP_BOOSTER:
      "You can use the Booster feature to prioritize certain campaigns over others to speed up delivery.",
    BOOSTERS_LIST: {
      NORMAL:
        "Default campaign delivery, Standard mode will deliver your campaign at a regular pace over the selected dates.",
      HIGH:
        "High priority mode will boost the delivery of your campaign x5, compared to other active campaigns.",
      HIGHEST:
        "Highest priority mode will boost the delivery of your campaign x10, compared to other active campaigns."
    }
  },
  NATIVE_REVENUE: {
    TOOLTIPS: {
      EARNING: "Estimated Total Net Native Ad Revenues",
      INVENTORY:
        "Number of times there was an opportunity to play a sponsored episode.",
      IMPRESSIONS: "Number of times a sponsored episode has been displayed.",
      CONVERSION_RATE:
        "Number of sponsored plays divided by the number of native ad impressions.",
      FILL_RATE:
        "The number of sponsored plays divided by the native ad inventory.",
      IMPRESSION_RPM:
        "Estimated average revenue generated per thousand native ads impressions.",
      PLAY_RPM:
        "Estimated average revenue generated per thousand organic plays."
    }
  },
  CLAIM_VALIDATION: {
    SIGNUP: "Sign up",
    ENTER_DETAILS_BELOW: "Enter your details below",
    FIRSTNAME: "First name",
    LASTNAME: "Last name",
    EMAIL: "E-mail",
    PASSWORD: "Password",
    CONFIRM_PASSWORD: "Confirm password",
    READ_AND_AGREE: "I’ve read and I agree with",
    TERMS_AND_CONDITIONS: "the Terms & Condition",
    AND: "and",
    PRIVACY_POLICY: "Privacy Policy",
    FREE: {
      TITLE: "Sign up"
    },
    PREMIUM: {
      TITLE: "Claim validation for premium Podcast",
      TITLE_REGISTRED: "Claim validation",
      APPROVE: "Approve",
      DISAPPROVE: "Disapprove"
    },
    SUCCESS: {
      SIGN_IN: "Successful registration, you can log in"
    },
    ERROR: {
      MIN_CHARACTERS_2: "Enter at least 2 characters.",
      MIN_CHARACTERS_4: "Enter at least 4 characters.",
      EMPTY_INPUT: "Please enter something",
      EMAIL_WRONG_FORMAT: "Email as a wrong format",
      NOT_IDENTICAL_PASSWORD: "The passwords are not the same",
      EMPTY_CHECKBOX: "Please check the box",
      ERROR_SERVER: "Something went wrong",
      LINK_EXPIRED: "The link has expired",
      ERROR_PASSWORD:
        "Password should be at least 8 characters in length and should include at least one upper case letter, one number, and one special character."
    }
  },
  CATEGORIES: {
    TITLE: "List of Categories",
    DELETE_ITEM: "Are you sure you want to delete this item?",
    NEW_ITEM: "New Item",
    SAVE: "Save",
    CANCEL: "Cancel",
    OK: "OK",
    EXPORT: "Export",
    CREATED: "Category created successfully",
    UPDATED: "Category updated successfully",
    DELETED: "Category deleted successfully",
    ERROR_CREATED: "An error occurred, unable to create category",
    ERROR_UPDATED: "An error occurred, unable to update category",
    ERROR_DELETED: "An error occurred, unable to delete category"
  },
  USERS: {
    DELETED: "User deleted with success",
    DELETE_ACTION:
      'Are you sure you want to delete the account of User "{firstName} {lastName}" ?',
    DELETE_ERROR: "An error occurred",
    RESET_PWD_ACTION: "Are you sure you want to reset {name} password?",
    DIALOG: {
      DELETE_USER_TITLE: "Delete User",
      RESET_PWD: "Reset password"
    },
    DATATABLE: {
      EMAIL: "Email",
      FIRSTNAME: "First Name",
      LASTNAME: "Last Name",
      ROLES: "Roles",
      SCOPE: "Scope",
      STATUS: "Status",
      LAST_CONNECTION: "Last connection",
      ACTIONS: "Actions"
    }
  },
  MODAL: {
    TITLE_SUCCESS: "Information",
    TITLE_ERROR: "Error",
    OK: "OK",
    YES: "YES",
    NO: "NO"
  },
  EDIT_V2: {
    CONGRATULATIONS: "Congratulations, your campaign is now ready !",
    INSTRUCTIONS: "you will be redirected in ",
    INSTRUCTIONS_TIMER: "seconds or click",
    CLICK_HERE: "here",
    BUDGET_SPENT: "Spent: ",
    BUDGET_LEFT: "Budget left: ",
    BUDGET: "Budget: ",
    EPISODES: "episodes"
  },
  CAMPAIGNS_SETTINGS: {
    NEW: "New Campaign",
    CAMPAIGN_NAME: "Campaign name",
    COMPANY: "Company",
    RSS_FEED: "Your Podcast RSS Feed",
    BUDGET: "Your budget",
    CPL: "Your CPL (cost per listens)",
    LISTENS: "Listens to be delivered: ",
    FORM: {
      ERROR_CAMPAIGN_NAME: "Enter at least 3 letters",
      ERROR_COMPANY: "Select a company",
      ERROR_RSS_FEED: "Enter a valid feed",
      ERROR_BUDGET: "Your budget must be more or equal than ",
      ERROR_ADVERTISER: "Please add an advertiser",
      ERROR_AGENCY: "Please add an agency",
      ERROR_CPL: "Your CPL must be more or equal than ",
      SUBMIT: "Next"
    },
    TOOLTIPS: {
      ADVERTISER:
        "Please note that the advertiser name is visible by the listeners.",
      AGENCY: "Please note that the agency name is visible by the listeners.",
      AUTOPROMO: "Flag current campaign as autopromo"
    }
  },
  CAMPAIGN_NOTIFICATIONS: {
    ASK_NOTIFICATIONS:
      "Do you want to receive notifications about your campaign ?",
    KEEP_UPDATING: "Note that you will be advised about any new episode",
    FORM: {
      ERROR_EMAIL: "Enter a valid email",
      CANCEL: "Cancel",
      SUBMIT: "Submit"
    }
  },
  CAMPAIGN_LISTING_EPISODE: {
    SELECT_EPISODE: "You must select at least one episode",
    SUBMIT: "Next",
    PREVIOUS: "Previous"
  },
  CAMPAIGN_CONFIG_EPISODE: {
    WARNING: "Warning !",
    EXCEED_BUDGET: "You have exceeded your budget",
    PRIORITIZATION_WARING:
      'Prioritization "high" and "highest" will be most effective when applied to fewer campaigns',
    SUBMIT: "Next",
    PREVIOUS: "Previous",
    MODAL_DELETE: {
      TITLE: "Are you sure to delete this episode ?",
      CANCEL: "No",
      SUBMIT: "Yes"
    },
    FORM: {
      START_DATE: "Start date",
      END_DATE: "End date"
    }
  },
  CAMPAIGN_TARGETING: {
    FORM: {
      CATEGORIES_LABEL: "Select categories for your campaign",
      LANGUAGE_LABEL: "Select languages for your campaign",
      PUBLISHER_LABEL: "Exclude pusblishers",
      PROGRAM_LABEL: "Exclude Shows",
      SEARCH: "Search",
      SUBMIT: "Submit",
      PREVIOUS: "Previous"
    }
  },
  CAMPAIGN_LIST_V2: {
    CAMPAIGNS: "Campaigns",
    SEARCH: "Search",
    CREATE_CAMPAIGN: "Create campaign",
    DELETE_CAMPAIGN: {
      DELETE_LABEL: "Delete this campaign",
      CONFIRM: "Are you sure ?",
      SUBMIT: "Yes",
      CANCEL: "No"
    },
    DUPLICATE_CAMPAIGN: {
      LABEL: "Duplicate campaign",
      CONFIRM: "Name your campaign:",
      SUBMIT: "Submit",
      CANCEL: "Cancel"
    },
    MENU_ACTIONS: {
      DUPLICATE_CAMPAIGN: "Duplicate campaign",
      EDIT_CAMPAIGN: "Edit campaign",
      DELETE_CAMPAIGN: "Delete campaign",
      COPY_CAMPAIGN: "Copy campaign Id",
      DETAILS: "Campaign details",
      PAUSE_CAMPAIGN: "Pause the campaign",
      PLAY_CAMPAIGN: "Play the campaign"
    }
  },
  CAMPAIGN_HEADER: {
    ADVERTISER: "Advertiser",
    AGENCY: "Agency",
    PROGRAM_NAME: "Program name",
    TOOLTIP_THUMBNAIL: "Thumbnails program",
    NEW_PROGRAM: "New",
    PERIOD: "Period",
    TARGETING: "Targeting",
    TOOLTIP_TARGETING: "See Targeting",
    LANGUAGES: "Languages",
    TOOLTIP_LANGUES: "See Languages",
    BUDGET: "Budget"
  },
  CAMPAIGN_AUDIENCE: {
    LISTENS_PER_DAY: "Listens per day",
    GEOLOCALISATION: "Geolocalisation",
    DOMAINS: "Listens per domains"
  },
  INVOICES: {
    LIST: "Invoice | Invoices",
    SEARCH: "Search",
    MENU_ACTIONS: {
      DOWNLOAD: "Download"
    }
  },
  DASHBOARD_CONVERSION: {
    MENU: "Conversion",
    DATATABLE: {
      DOMAIN: "Domain",
      SESSIONS: "Sessions",
      PAGEVIEWS: "Page views",
      PLAYS: "Plays",
      SESSIONS_RATE: "% Session",
      PAGEVIEWS_RATE: "% Page view"
    },
    CARDS: {
      TOOLTIPS: {
        SESSIONS: "Number of Sessions",
        PAGES: "Number of Page views",
        PLAYS: "Number of Plays",
        SESSIONSRATE:
          "Number of organic plays divided by the number of sessions",
        PAGESRATE: "Number of organic plays divided by the number of pages"
      }
    }
  },
  DETAILS_V2: {
    GRAPH_TITLE: "Graph",
    GRAPH_TITLE_DOUGHNUT: "Completion Rate",
    LOADING: "Loading...",
    ORDER_LINES_TABLE_TITLE: "Stats Order lines items",
    CAMPAIGN_NOT_READY: "This campaign has just started",
    NO_STATS:
      "No stats is available for the moment. Be patient, come back later !",
    NO_USERS: "No users found for this company",
    CAMPAIGN_INFORMATIONS_TITLE: "Campaign Informations",
    ITEMS_LINES_TITLE: "Items Line",
    CHANGE_LOGS_TITLE: "Campaign change logs"
  },
  EDIT_PODCAST: {
    HOMEDISPLAY_CONFIRMATION:
      "Are you sure you want to set {action} the {type} <b>{name}</b> ?",
    IMPORT_CONFIRMATION:
      "Are you sure you want to set {action} import the {type} <b>{name}</b> ?",
    MESSAGE_ONLINE: "The program is online",
    MESSAGE_OFFLINE: "The program is offline",
    MESSAGE_IMPORT_ENABLED: "Import program is enabled",
    MESSAGE_IMPORT_DISABLED: "Import program is disabled"
  },
  AUDIENCE_PAGE: {
    TITLE_HEATMAP: "Listens per day",
    TITLE_DOGHNUT_PROGRAMS: "Listens per programs",
    TITLE_CHART_DEVICE: "Listens per devices",
    TITLE_RANKING_EPISODE: "Top Episodes"
  },
  LOG: {
    CREATED_AT: "Date & time",
    USER: "User",
    FIELDS_CHANGED: "Description",
    ACTION: "Type",
    LEVEL: "Level",
    RESOURCE_ID: "Resource id"
  },
  RANKING_EPISODE: {
    TOP: "Top",
    NAME: "Episode",
    DOMAIN: "Domain",
    PROGRAM: "Program",
    LISTENING_TOTAL: "Total listens",
    LISTENING_AVG: "Average listens per day",
    LISTENING_TIME_TOTAL: "Total listening time",
    LISTENING_AVG_TIME: "Average listening time",
    TITLE_HEATMAP: "Listens per day"
  },
  ADVANCED_SETTINGS: {
    LISTING_EPISODES: {
      LABEL: "Episode list",
      TOTAL: "Total of episodes",
      LABEL_EMBED: "Copy Embed",
      LABEL_OEMBED: "Copy oEmbed",
      LABEL_URL: "Copy URL",
      LABEL_DELETE: "Delete",
      LABEL_CLEAN: "Clean image",
      LABEL_LOADING: "Loading..."
    },
    MENU: {
      DOMAINS: "Domains",
      PODCASTS: "Podcasts",
      PLAYLISTS: "Playlists",
      USERS: "Users",
      INVOICES_INFORMATIONS: "Invoicing Informations"
    },
    ACTIVE: "Active",
    URL_DOMAINS: "URL Domains:",
    ACCOUNT_TYPE: "Account Type:",
    LOAD_MORE: "Load more",
    PROGRAMS: {
      TITLE: "Content",
      FILTER_PLAYLISTS: "Playlists only"
    },
    DOMAIN: {
      TITLE: "Domains",
      LOADING: "Domain is loading",
      NO_DOMAIN_CREATE: "No domain to display",
      NO_DOMAIN: "Please select a domain or",
      CREATE_DOMAINE: "Create one",
      CONFIG_DOMAIN: {
        TITLE: "Domains",
        COMPANY: "Company",
        LABEL_ENABLED: "Enabled",
        LABEL_NAME: "Name",
        LABEL_DOMAINS: "Hostname",
        LABEL_TITLE: "Title",
        LABEL_DESCRIPTION: "Description",
        LABEL_TEMPLATE: "Template",
        MODAL: {
          LANGUAGE: {
            TITLE: "Remove language",
            BODY: "Are you sure you want to remove this language ?"
          }
        }
      },
      CONFIG_DESIGN: {
        TITLE: "Design",
        LABEL_LOGO: "Logo",
        LABEL_LOGO_LINK: "Logo Link",
        LABEL_FAVICON: "Favicon",
        TITLE_COLORS_FONTS: "Colors & fonts",
        LABEL_BACKGROUND_COLOR: "Background color:",
        LABEL_FONT_COLOR: "Font color:"
      },
      CONFIG_HOME_HIGHLIGHT_PROGRAMS: {
        TITLE: "Podcast Highlight settings",
        MESSAGE: `
        Highlight is a feature that allows you to select the programs and episodes you want to showcase on your Audiohub subdomain.</br>
        - Choose the programs to highlight</br>
        - Set the display rules</br>
        - And you're all set!
        `,
        SELECTOR: {
          PROGRAMS: {
            LABEL: "Podcasts",
            BUTTONTEXT: "Choose the podcast(s)",
            HELPER:
              "Choose the podcast(s) you want to highlight from all your available podcasts"
          },
          MODE: {
            LABEL: "Mode",
            HELPER: `
             - Latest: The most recent episode will always appear first. Retrieves the last 3 episodes from the selected programs. If no program is selected, the entire catalog is considered.</br>
             - Ordered: The thumbnail display will maintain the selection order. Retrieves the last 3 episodes from the selected programs.</br>
             - Shuffle: Selects 3 episodes from the last 50 episodes of the chosen programs. If no program is selected, the entire catalog is considered.</br>
             `
          }
        }
      },
      CONFIG_DISCOVERY: {
        TITLE: "Discovery settings",
        LABEL_CSS: "CSS Selectors:",
        LABEL_ADD_CSS: "Add CSS Selector",
        ERROR_CSS: "Please put your CSS",
        LABEL_COPY_CPLIPBOARD:
          "If you want to retrieve the script code from your clipboard, click on this icon.",
        ALERT: {
          TITLE:
            "This section allows you to configure the Discovery feature of Voxeus",
          PARAGRAPH:
            "Discovery is an intelligent audio player that, based on semantic analysis of your page's content, displays the most relevant episodes from your catalog.",
          FOOTER:
            "To set up Discovery add below the CSS Selectors that you want."
        },
        TOOLTIPS: {
          LABEL_CSS:
            "List of CSS Selectors separate by comma. Becarefull, order is important.",
          LABEL_COPY: "Copy discovery code"
        }
      },
      CONFIG_CODE_INTEGRATION: {
        TITLE: "Code integration",
        MESSAGE:
          "The 'Code integration' space allows you to add any code you want to your audiohub, \"{domainName}\": add analytics tags, HTML code to insert a header, etc. Do you have any question? Contact our support: support@voxeus.com or check out our FAQ.",
        LABEL_ADD_CODE: "Add code to your domain",
        MODAL_RULES: {
          TITLE: "Important",
          FIRST_PART_RULES:
            "By checking this box, you waive Voxeus of any responsibility for any technical issues that may arise.",
          SECOND_PART_RULES:
            "Adding code may potentially alter or interfere with the technical functionalities provided by Voxeus's technical team.",
          LABEL_AGREE_RULES: "I agree",
          SUBMIT_RULES: "Submit",
          CANCEL_RULES: "Cancel"
        },
        LABEL_ACTIVATE: "Activate",
        ERRORS_TITLE: "Enter at least 3 letters",
        ERROR_TYPE: "Please select one script type",
        ERROR_SRC: "Add your external code here with https://",
        ERROR_SCRIPT: "Add your code here",
        MODAL_DELETE: {
          TITLE: "Confirmation",
          LABEL_DELETE:
            "Are you sure you want to remove this code integration ?",
          SUBMIT: "Confirm",
          CANCEL: "Cancel"
        }
      },
      CONFIG_SECTIONS: {
        TITLE: "Sections",
        PLACEHOLDER: "Put your sections here",
        ERRORS_TITLE: "Enter at least 3 letters",
        ERRORS_SECTIONS: "Enter at least 3 letters",
        LABEL_ADD_SECTIONS: "Add sections",
        LABEL_CREATE: "Sections create:",
        ALERT: {
          LABEL:
            "This space allows you to configure the sections that will appear on your 'Audiohub' space, your dedicated audio space for podcasts. Create sections (for example: 'News,' 'Archives', etc.) to organize and editorialize your podcasts as you wish."
        },
        MODAL_DELETE: {
          TITLE: "Confirmation",
          LABEL_DELETE: "Are you sure you want to remove this sections ?",
          SUBMIT: "Confirm",
          CANCEL: "Cancel"
        }
      },
      CONFIG_LINKS: {
        TILE: "Links",
        LABEL_TERMS: "Terms link",
        LABEL_PRIVACY: "Privacy link",
        LABEL_LEGALS: "Legals link",
        LABEL_LINKS: "About link",
        WEBSITE: {
          LABEL: "Logo link",
          TOOLTIP:
            'Enter here the link located at the level of the logo on your "audiohub'
        }
      },
      CONFIG_ADS: {
        TITLE: "Advertisement (Admin)",
        LABEL_AUTH_ADS: "Authorized Ads",
        LABEL_ADS_AUDI_ADS: "Activate Audio Ad",
        LABEL_ADS_BACKFILL: "Audio ads backfill",
        LABEL_SELLERS: "Sellers",
        PUBLISHER_AUDIOADS_CPM: "Publisher AudioAds CPM",
        PUBLISHER_AUDIOADS_FILLRATE: "Publisher AudioAds Fill Rate"
      },
      CONFIG_CONF: {
        TITLE: "Conf (Admin)",
        LABEL_REDIRECT_ALONE: "Redirect alone",
        LABEL_ADVERTISER: "Is advertiser ?",
        LABEL_REDIRECT_TO: "Redirecto to",
        LABEL_DEFAULT_LOCALE: "Default Locale",
        LABEL_ORGANIZATION_NAME: "Organization name"
      },
      CONFIG_GOOGLE_SEARCH: {
        TITLE: "Google search (Admin)",
        LABEL_ROBOTS: "Robots google verification"
      },
      CONFIG_ONE_SIGNAL: {
        TITLE: "One signal (Admin)",
        LABEL_ID: "One signal ID",
        LABEL_AUTH: "One signal Auth",
        LABEL_DATABASE: "One signa Database"
      },
      CONFIG_GDPR: {
        TITLE: "GDPR (Admin)",
        LABEL_CMP: "CMP"
      },
      CONFIG_ACPM: {
        TITLE: "ACPM (Admin)",
        LABEL_BRAND_ID: "ACPM Brand Id"
      },
      FORM_ERROR: {
        DOMAIN: "Please select a domain",
        COMPANY: "Please select a company",
        NAME: "Enter at least 3 letters",
        DOMAIN_NAME: "Enter at least 3 letters",
        TITLE: "Enter at least 3 letters",
        DESCRIPTION: "Enter at least 3 letters",
        MULTILINGUAL: "Enter at least 3 letters (on each tabs)",
        TEMPLATE: "Enter at least 3 letters",
        TERMS_LINK: "Insert your link in the following format: https://",
        PRIVACY_LINK: "Insert your link in the following format: https://",
        LEGAL_LINK: "Insert your link in the following format: https://",
        ABOUT_LINK: "Insert your link in the following format: https://",
        WEBSITE: "Insert your link in the following format: https://"
      },
      TOAST: {
        SUCCESS: {
          TITLE: "Domain",
          MESSAGE_CREATE: "Domain create with success",
          MESSAGE_UPDATE: "Domain update with success"
        },
        ERROR: {
          TITLE: "Domain",
          MESSAGE: "Something went wrong."
        }
      },
      SUBMIT: "Save changes"
    },
    INVOICING: {
      USERS: {
        CREATE: "Create a user",
        EDIT: "Edit a user",
        DELETE: "Delete a user",
        DELETE_CONFIRMATION:
          "You are about to delete the user <b><u>{email}</u></b>. <br>Please confirm ?",
        RESET: "Reset password",
        RESET_CONFIRMATION:
          "Are you sure you want to reset <b><u>{email}</u></b> password?",
        BLOCKED: "Blocked / Unblocked user",
        WELCOME: "Welcome user",
        BLOCKED_CONFIRMATION:
          "Are you sure you want to {action} the user <b><u>{email}</u></b>?",
        WELCOME_CONFIRMATION:
          "Are you sure you want to send a welcome email to the user <b><u>{email}</u></b>?",
        LABEL_ADD_USER: "Add user",
        FORM: {
          INFORMATION: "Information",
          EMAIL: "Email",
          FIRSTNAME: "First name",
          LASTNAME: "Last name",
          FUNCTION: "Function",
          ROLE: "Role",
          ADMIN: "Admin",
          PAID: "Paid",
          DOMAIN: "Domain",
          DOMAINS: "Domains",
          PODCASTS: "Podcasts",
          LABEL_DOMAINS:
            "Choose the domains that the user should be able to access",
          LABEL_PROGRAMS:
            "Choose the programs that the user should be able to access",
          FORM_ERROR: {
            WRONG_FORMAT: "Wrong format",
            REQUIRED: "Required field",
            SELECTED: "Select at least one item"
          }
        },
        TOAST: {
          TITLE: "User",
          MESSAGE_CREATE: "The user has been created",
          MESSAGE_DELETE: "The user has been deleted",
          MESSAGE_RESET: "The email to reset the password has been sent",
          MESSAGE_BLOCKED: "The user has been blocked",
          MESSAGE_UNBLOCKED: "The user has been unblocked",
          MESSAGE_WELCOME: "The email has been sent to the user",
          ERROR_MESSAGE: "Something went wrong."
        },
        BUTTON: {
          CANCEL: "Cancel",
          SUBMIT: "Submit",
          CONFIRM: "Confirm"
        }
      },
      NEW: "New company",
      SECTION_INFOS: "Company main infos",
      SECTION_ACPM: "ACPM",
      SECTION_ADMIN: "Settings (Admin)",
      SECTION_USERS: "Users",
      FIELD_CONTACT: "Contact",
      FIELD_ADDRESS: "Address",
      FIELD_INFORMATION: "Information",
      FIELD_HOSTING: "Hosting",
      FIRST_NAME: "First name",
      LAST_NAME: "Last name",
      EMAIL: "Email",
      PHONE: "Phone",
      ADDRESS: "Address",
      CITY: "City",
      ZIP_CODE: "Zip code",
      COUNTRY: "Country",
      VAT_NUMBER: "VAT Number",
      RCS: "RCS",
      ENABLED: "Enabled",
      NAME: "Company name",
      TECH_FEES: "Tech Fees",
      REVENUE_SHARE: "Revenue Share",
      COMPANY_TYPE: "Company type",
      PASSWORD_EXPIRATION_DAYS: "Password expiration days",
      FORM_ERROR: {
        NOT_EMPTY: "Must not be empty",
        WRONG_FORMAT: "Wrong format"
      },
      TOAST: {
        TITLE: "Invoicing",
        SUCCESS: {
          MESSAGE_CREATE: "Invoicing create with success",
          MESSAGE_UPDATE: "Invoicing update with success"
        },
        ERROR: {
          MESSAGE: "Please check the form data are valid",
          MESSAGE_API: "There are errors in submitting your data"
        }
      },
      INVOICE: "Invoice",
      INVOICING_NAME: "Invoicing Name",
      VAT_PERCENT: "VAT percent",
      CURRENCY: "Currency",
      AUTOINVOICE: "Automatic invoice"
    },
    HOSTING: {
      TITLE: "Hosting",
      ACCESS: "Access",
      ACPM_GROUP_ID: "ACPM Group ID",
      ACPM_BRAND_ID: "ACPM Brand ID"
    },
    CONTENT: {
      PROGRAMS_FOUND: "No programs found",
      NO_PROGRAM: "Please select a program or",
      ERROR_SEND: "Something went wrong ! Need help ? contact us :",
      EPISODES_LIST: "Access Episodes list",
      RSS_FEED: "RSS Feed",
      DOMAIN_PROGRAM: "Listen",
      PLACEHOLDER_SEARCH_PROGRAMS: "Search programs",
      LABEL_FILTER_PLAYLIST: "Playlist only",
      LABEL_ORDER: "Change order",
      LABEL_ADD_CONTENT: "Add content",
      CHANGE_PROGRAM_ORDER: "Change order",
      ADD_CONTENT: "Add Content",
      IMPORT: {
        LABEL_PROGRAM: "Import podcast",
        INPUT_FEEDURL_LABEL: "Enter the podcast feed url :",
        INPUT_FEEDURL_PLACEHOLDER: "Feed URL",
        SELECT_DOMAIN_PLACEHOLDER: "Select a Domain",
        SELECT_PARTITION_PLACEHOLDER: "Select a Partition",
        BUTTON: {
          CANCEL: "Cancel",
          IMPORT: "Import"
        },
        VALIDATOR: {
          INVALID_FEEDBACK: "Please enter a valid feed url",
          ITEM_REQUIRED: "Item is required",
          MESSAGESERVICE: {
            SUCCESS_IMPORTED: "Program imported",
            SUCCESS_UPDATED: "Program updated",
            ERROR_IMPORTED:
              "Something was wrong. Please contact us : support@voxeus.com",
            ERROR_NO_FILEDS_EMPTY: "Please fill in all the fields."
          }
        }
      },
      CHOICES: {
        LABEL_PROGRAM: "Import podcast",
        TEXT_PLAYLIST:
          "Create your own playlist from your catalog of episodes.",
        LABEL_PLAYLIST: "Create a playlist",
        TEXT_PROGRAM:
          "Copy and paste the feed of your podcast and automatically import all of its episodes."
      },
      THUMBNAILS: {
        EPISODES: "Episodes",
        PLAYLIST: "Playlist",
        DISABLED: "Disabled",
        NEW_PROGRAM: "New"
      },
      CONFIG_PROGRAM: {
        TITLE: "General Settings",
        LOADING: "Podcast is Loading",
        LABEL_DOMAIN: "Domain",
        LABEL_PLAYLIST_NAME: "Playlist title",
        LABEL_PROGRAM_NAME: "Program name",
        LABEL_SUBTITLE: "Subtitle",
        TOOLTIP_SUBTITLE:
          "The subtitles will improve the natural referencing on search engines and streaming platforms, thus increasing the visibility of your podcasts through keywords.",
        LABEL_FLUX: "Feed URL",
        LABEL_PATH: "Path URL",
        LABEL_COPYRIGHT: "Copyright link",
        LABEL_ORDER: "Order of appearance",
        TOOLTIP_ORDER:
          'The order of appearance refers to the location of your playlist among all your other podcasts. If you want your playlist to appear in the first position on your audio subdomain ("Audiohub"), then indicate "1".',
        LABEL_COPYRIGHT_EMAIL: "Copyright email",
        LABEL_DESCRIPTION: "Description",
        LABEL_EXPLICIT: "Explicit content",
        TOOLTIP_EXPLICIT:
          "If your podcast episode contains any words, topics or references to any content that may be considered age sensitive subjects, then we usually recommend you mark your episode as explicit. It doesn't impact your podcast at all, it just gives it a parental advisory label.",
        TOOLTIP_DESCRIPTION:
          "Describe in a few lines the content of your podcast, find the tone that defines it. Use keywords related to your content. Spark your listeners' curiosity with catchy and clear sentences.",
        LABEL_THUMBNAILS: "Picture (1400 * 1400)",
        TOOLTIP_THUMBNAILS:
          "For optimal rendering, upload an image of 1400 x 1400px. The minimum expected quality for a podcast image is 400 x 400px.",
        FORMAT_THUMBNAILS: "The following formats are accepted: png, jpeg, jpg",
        LABEL_ITUNES: "Itunes category",
        TOOLTIP_ITUNES:
          "This category allows your listeners to find content related to a specific theme and ensure that your podcast is well referenced",
        LABEL_TYPE: "Publication order",
        TOOLTIP_TYPE:
          "Episodic: New episodes appear first, and there's no listening order. Serial: Episodes must be listened to in order.",
        LABEL_AUTHOR: "Author",
        TOOLTIP_AUTHOR: "This name will be publicly displayed to the listeners",
        LABEL_LANGUAGES: "Language",
        TOOLTIP_LANGUAGES:
          "Choosing the language helps to better categorize and recommend your podcast based on the user's language."
      },
      CONFIG_ADVERTISEMENT: {
        TITLE: "Ads Settings (Admin)",
        TITE_NATIVE_ADS: "Native Ads",
        TITLE_AUDIO_ADS: "Audio Ads Tag",
        LABEL_ADS: "Activate Native Ads",
        LADEL_AUDIO_ADS: "Activate Audio Ads",
        LABEL_BACK_FILL: "Back fill only",
        LABEL_AUDIO_ADS: "Audio ads URL",
        LABEL_AUDIO_ADS_CONFIGURATION: "Audio ads configuration",
        LABEL_CAPPING: "Capping time settings",
        LABEL_ENABLED_CAPPING: "Capping",
        ERROR_TIME_CAPPING: "This value must be greater than 0",
        BRAND: "Brand"
      },
      CONFIG_ADVANCED: {
        TITLE: "Advanced (Admin)",
        LABEL_HOME: "Home display",
        LABEL_FORCE: "Force",
        LABEL_REDIRECT_TO: "Redirect to",
        LABEL_PARTITION: "Partition",
        LABEL_PARENT: "Parent",
        LABEL_LANGUAGES: "Tag languages",
        LABEL_CATEGORIES: "Tag IAB"
      },
      CONFIG_EPISODE: {
        TITLE: "Episodes selection",
        WARNING: "Please select at least one episode",
        LOADING: "Loading",
        LABEL_SELECT_COL: "Select"
      },
      FORM_ERROR: {
        DOMAIN: "Please select a domain",
        COMPANY: "Please select a domain",
        FLUX: "Wrong format",
        FEED_URL: "Please put an RSS feed",
        COPYRIGHT_LINK: "Insert your link in the following format: https://",
        COPYRIGHT_EMAIL: "Wrong format",
        THUMBNAILS: "Format: JPG or PNG, maximum size: 10 MB.",
        CATEGORIY: "Please select a category",
        DESCRIPTION: "Please enter a description to present your playlist",
        AUTHOR: "Wrong format",
        TYPE: "Please select one item",
        NAME: "Invalid name"
      },
      TOAST: {
        EPISODE: {
          SUCCESS: {
            TITLE: "Episode",
            MESSAGE_CREATE: "Episode create with success",
            MESSAGE_UPDATE: "Episode update with success",
            MESSAGE_DELETE: "Episode remove with success",
            MESSAGE_CHANGE_ORDER: "Episode moved successfully"
          }
        },
        SUCCESS: {
          TITLE: "Program",
          MESSAGE_CREATE: "Program create with success",
          MESSAGE_UPDATE: "Program update with success",
          MESSAGE_DELETE: "Program remove with success",
          MESSAGE_CHANGE_ORDER: "Program moved successfully"
        },
        SUCCESS_PLAYLIST: {
          TITLE: "Playlist",
          MESSAGE_CREATE: "Playlist create with success",
          MESSAGE_UPDATE: "Playlist update with success",
          MESSAGE_DELETE: "Playlist remove with success",
          MESSAGE_CHANGE_ORDER: "Playlist moved successfully"
        },
        ERROR: {
          TITLE: "Program",
          MESSAGE: "Something went wrong."
        }
      },
      SUBMIT: "Submit",
      SAVE: "Save",
      CLOSE: "Close",
      LABEL_DELETE: "Delete this program",
      TOOLTIP_DELETE: "Please contact your manager to delete this content",
      DELETE: {
        TITLE: "Delete program",
        LABEL: "Are you sure you want to remove this program ?",
        CANCEL: "Cancel",
        SUBMIT: "Confirm"
      },
      DELETE_EPISODE: {
        TITLE: "Delete episode",
        LABEL: "Are you sure you want to remove this episode ?",
        CANCEL: "Cancel",
        SUBMIT: "Confirm"
      },
      BROADCAST: {
        TITLE: "Publish"
      }
    }
  },
  PROGRAMS_PAGE: {
    PROGRAMS_DRAGGABLE: {
      TITLE: "Change the order of podcasts",
      LABEL_HELPER: "You can move the podcasts to change the order or sections",
      ERROR: "Something went wrong. Try again later",
      CANCEL: "Close",
      SUBMIT: "Save"
    },
    LOADING: "Content loading, please wait..",
    ERROR_LOADING:
      "An error occurred on loading page... Please try to reload it.",
    LABEL_RELOAD: "Reload",
    NO_PROGRAMS_FOUND: "No programs found"
  },
  PODCASTS: {
    TOAST: {
      TITLE: "Podcasts"
    }
  }
};
